<template>
    <div class="flex flex-nowrap w-full mt-6 mb-4">
        <Button
            :loading="loading"
            aria-label="Voltar"
            class="border-200"
            icon="pi pi-chevron-left"
            style="color: #3f51b5; background-color: #f8fafc; width: 40px; height: 40px"
            @click="emit('prevPage')"
        />
    </div>
    <div class="w-full text-center font-bold poppins-medium mt-3" style="font-size: 28px">Data e hora da consulta</div>
    <span class="text-center mt-2" style="font-size: 18px"> Preencha com a data e hora de sua preferência </span>
    <div v-if="!reservaDeHorario" class="grid mt-4 px-2">
        <div class="col-6 p-3">
            <label class="text-2xl text-700" for="dataAgendamento"> Data </label>
            <Calendar
                id="dataAgendamento"
                v-model="agendamento.dataAgendamento"
                :disabledDays="diasNaoAtendidos"
                :loading="loading"
                :maxDate="dataSugestaoFinal"
                :minDate="dataMinima"
                class="w-full mt-1"
                dateFormat="dd/mm/yy"
                inputClass="border-2 border-round-xl p-4 text-xl font-medium"
            />
        </div>
        <div class="col-6 p-3">
            <label :class="{ 'text-500': !agendamento.dataAgendamento, 'text-700': agendamento.dataAgendamento }" class="text-2xl" for="periodo">
                Período
            </label>
            <Dropdown
                id="periodo"
                v-model="agendamento.periodo"
                :disabled="!agendamento.dataAgendamento"
                :options="periodos"
                class="w-full mt-1 border-2 border-round-xl"
                inputClass="p-4 text-xl font-medium"
                optionLabel="label"
                optionValue="value"
            />
        </div>
    </div>
    <div v-else class="mt-4 w-full">
        <label class="text-2xl text-700" for="horarioAgendaSOC"> Horário </label>
        <Dropdown
            id="horarioAgendaSOC"
            v-model="agendamento.procedimentosFromSoc"
            :loading="loading"
            :options="faixasDeHorario"
            :optionLabel="formatarHorariosAgenda"
            class="w-full mt-1 border-2 border-round-xl"
            filter
            inputClass="p-4 text-xl font-medium"
            placeholder="Selecione o horário"
        />
    </div>
    <Button
        :disabled="!camposEstaoPreenchidos"
        :loading="loading"
        class="border-none text-xl w-full mt-auto mb-5 py-3 poppins-medium"
        label="Confirmar agendamento"
        rounded
        style="background-color: #3f51b5"
        @click="confirmarAgendamento()"
    />
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onActivated, ref, defineEmits, computed } from 'vue';
import { getAutoagendamentoToken } from '@/common/storage';
import { logoutAutoagendamento } from '@/services/auth';
import { getClientBase } from '@/services/http';
import { diaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';
import EnumPeriodo from '@/enums/EnumPeriodo';
import moment from 'moment';
import dayjs from 'dayjs';

const emit = defineEmits(['prevPage', 'nextPage']);

const autoagendamentoToken = getAutoagendamentoToken();
const auxHeaders = { Authorization: `Bearer ${autoagendamentoToken}` };

const loading = ref(false);
const toast = useToast();
const dataSugestaoInicial = ref(null);
const dataSugestaoFinal = ref(null);
const faixasDeHorario = ref([]);
const diasAtendidosPrestador = ref([]);
const reservaDeHorario = ref(false);
const agendamento = ref({
    dataAgendamento: null,
    periodo: null,
    procedimentosFromSoc: null
});

const camposEstaoPreenchidos = computed(() => {
    if (reservaDeHorario.value) {
        return !!agendamento.value.procedimentosFromSoc;
    }
    return !!agendamento.value.dataAgendamento && agendamento.value.periodo;
});
const dataMinima = computed(() => {
    const hoje = dayjs();
    const dataSugestao = dayjs(dataSugestaoInicial.value);
    const passouDaDataInicial = hoje.format('YYYY-MM-DD') > dataSugestao.format('YYYY-MM-DD');

    return passouDaDataInicial ? hoje.toDate() : dataSugestao.toDate();
});
const diasNaoAtendidos = computed(() => {
    const diasDaSemana = Object.entries(diaDaSemanaPorIndice);
    const diasAtendidos = diasAtendidosPrestador.value?.map((dia) => dia.weekDay);

    if (!diasAtendidos) return [0, 6];
    return diasDaSemana.reduce((dias, diaDaSemana) => {
        if (!diasAtendidos.includes(diaDaSemana[1])) {
            dias.push(+diaDaSemana[0]);
        }

        return dias;
    }, []);
});
const periodos = computed(() => {
    const { dataAgendamento } = agendamento.value;
    if (!dataAgendamento) return [];

    const data = dayjs(dataAgendamento).format('YYYY-MM-DD');
    const dataEHoje = data === dayjs().format('YYYY-MM-DD');
    const agoraEManha = !dataEHoje || dayjs().format('HH:mm') <= '12:00';

    const periodo = [
        { label: 'Manhã', value: EnumPeriodo.MANHA, visible: agoraEManha },
        { label: 'Tarde', value: EnumPeriodo.TARDE, visible: true },
        { label: 'Ambos', value: EnumPeriodo.AMBOS, visible: agoraEManha && !reservaDeHorario.value }
    ];
    return periodo.filter((p) => p.visible === true);
});

onActivated(async () => {
    await buscarDataSugestao();
    await buscarUsaReservaDeHorario();
    if (reservaDeHorario.value) {
        buscarFaixasDisponiveis();
    } else {
        buscarDiasAtendidosPeloPrestador();
    }
});

async function buscarDataSugestao() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/data-sugestao');
        dataSugestaoFinal.value = dayjs(data.dataSugestaoFinal).toDate();
        dataSugestaoInicial.value = dayjs(data.dataSugestaoInicial).toDate();
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function buscarFaixasDisponiveis() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get(`autoagendamento/faixas-disponiveis`, {
            params: {
                dataInicial: dataMinima.value,
                dataFinal: dataSugestaoFinal.value
            }
        });
        faixasDeHorario.value = data;
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function buscarDiasAtendidosPeloPrestador() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get(`autoagendamento/prestador-dias-atendidos`);
        diasAtendidosPrestador.value = data;
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function buscarUsaReservaDeHorario() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/reserva-horario');
        reservaDeHorario.value = data;
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function confirmarAgendamento() {
    loading.value = true;
    try {
        await getClientBase(auxHeaders).post('/autoagendamento/confirmar-agendamento', agendamento.value);
        emit('nextPage');
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
const formatarHorariosAgenda = (horario) => {
    const diaDoMes = moment.tz(horario.horarioChegada, 'UTC').format('DD/MM');
    const horarioChegada = moment.tz(horario.horarioChegada, 'UTC').format('HH:mm');
    const horarioSaida = moment.tz(horario.horarioSaida, 'UTC').format('HH:mm');
    const duracao = horario.minutosAgendas;

    return `${diaDoMes} | Entrada ${horarioChegada} - Saída ${horarioSaida} (${duracao}min)`;
};
</script>
